@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins';
  margin: 0;
  padding: 0;
}

.container-class {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.login-bg {
  background-image: url('../images/userDetails/Bg\ Pattern\ \(1\).svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: inherit;
  /* height: 100vh; */
}

.accountPayable-bg {
  background-image: url('../images/userDetails/Bg\ Pattern\ \(1\).svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.procedure-bg {
  background-image: url('../images/home//Group\ 5411.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}

.ourProduct-bg {
  background-image: url('../images/userDetails/Bg\ Pattern\ \(1\).svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 777px;
}

.industry-insider-bg {
  background-image: url('../images/userDetails/Bg\ Pattern\ \(1\).svg');
  height: 200px;
}

.faq-bg {
  background-image: url('../images/userDetails/Bg\ Pattern\ \(1\).svg');
  height: 200px;
}

.paper-invoices-bg {
  background-image: url('../images/userDetails/Bg\ Pattern\ \(1\).svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 777px;
}

.down-arrow {
  background-image: url('../images/contact/Vector.svg');
  background-position: right 20px center;
  background-repeat: no-repeat;
}

.payment-modal-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.payment-modal-content {
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.payment-form {
  display: flex;
  flex-direction: column;
}

.card-element {
  margin-bottom: 30px !important;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.payment-button {
  padding: 10px 20px;
  background-color: #007bff !important;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-button:disabled {
  background-color: #aaa;
}

.payment-button:not(:disabled):hover {
  background-color: #0056b3;
}

.payment-message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
}

.payment-error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.payment-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.green-i {
  font-size: 68px;
  position: relative;
}

.green-i:before {
  content: '';
  display: inline;
  background: #00D588;
  height: 16px;
  width: 16px;
  border-radius: 50px;
  position: absolute;
  top: -5px;
  left: -2px;
}

@media screen and (max-width: 1280px) and (min-width: 800px) {
  .ourProduct-bg {
    height: 600px;
  }
}

@media screen and (max-width: 800px) and (min-width: 320px) {
  .ourProduct-bg {
    height: 450px;
  }
}

@media screen and (max-width: 1279px) and (min-width: 970px) {
  .paper-invoices-bg {
    height: 1200px;
  }
}

@media screen and (max-width: 969px) and (min-width: 875px) {
  .paper-invoices-bg {
    height: 1100px;
  }
}

@media screen and (max-width: 874px) and (min-width: 768px) {
  .paper-invoices-bg {
    height: 1200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 690px) {
  .paper-invoices-bg {
    height: 1000px;
  }
}

@media screen and (max-width: 689px) and (min-width: 320px) {
  .paper-invoices-bg {
    height: 900px;
  }
}

@media screen and (max-width: 1279px) and (min-width: 320px) {
  .green-i {
    font-size: 44px;
    position: relative;
  }

  .green-i:before {
    height: 10px;
    width: 10px;
    top: -3px;
    left: -0.9px;
  }
}


.relative.overflow-auto.max-h-full::-webkit-scrollbar {
  width: 8px;
}

.relative.overflow-auto.max-h-full::-webkit-scrollbar-thumb {
  background-color: #00D588;
  border-radius: 10px;
}

.relative.overflow-auto.max-h-full::-webkit-scrollbar-track {
  background-color: transparent;
}


@font-face {
  font-family: "Nohemi";
  src: local("Nohemi"),
    url("../../fonts/Nohemi-Regular-BF6438cc58b98fc.otf") format("truetype"),
    local("Nohemi"),
    url("../../fonts/Nohemi-Medium-BF6438cc581a509.otf") format("truetype"),
    local("Nohemi"),
    url("../../fonts/Nohemi-SemiBold-BF6438cc588b5e5.otf") format("truetype"),
    local("Nohemi"),
    url("../../fonts/Nohemi-Bold-BF6438cc5812315.otf") format("truetype");

  font-weight: 300, 400, 500, 600, 700, 800, 900;
}

.group:hover .svg-icon .svg-circle {
  fill: #00D588;
}

.group:hover .svg-icon .svg-path {
  fill: #004862;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nohemi', sans-serif;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 50%;
}